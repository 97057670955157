import { StackDivider, VStack } from '@chakra-ui/react';

import { memo } from 'react';

import dynamic from 'next/dynamic';
const PostItem = dynamic(() => import('../../shared/PostItem'));

const WideCards = props => {
  const { attrs, initialData } = props;

  const posts = initialData?.items;

  if (posts?.length === 0) {
    return null;
  }

  return (
    <>
      <VStack
        w='full'
        align={'stretch'}
        spacing={4}
        divider={<StackDivider borderColor='gray.200' />}
        borderBottomWidth={1}
        borderBottomColor='gray.200'
        py='4'
      >
        {Array.isArray(posts) &&
          posts.map(item => <PostItem key={item?.id} {...item} />)}
      </VStack>
    </>
  );
};

export default memo(WideCards);
